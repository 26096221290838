
.button{

  //border: 3px solid $orange-dark;
  @include border-radius(2em);
  @include scale(1);
  @include animate;
  padding: 1em 2em;
  @include gradient(90deg, $grad1);

  color: $white;
  font-weight: bold;


  &:hover{
    @include animate;
    @include scale(1.05);
    color: $white;
    text-decoration: underline !important;
  }

}
