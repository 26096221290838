
$maxWidth:        1800px;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;1,400&family=Open+Sans:ital@0;1&display=swap');

$titleFont:   'Montserrat', sans-serif;
$bodyFont: 'Open Sans', sans-serif;


//Colours
$white:          #ffffff;
$black:          #000000;
$grey-dark:      #504951;
$grey-mid:       #504951;
$grey-light:     #EBE9E8;

$red-dark:       #4C071B;
$red:            #6D0E1F;
$orange:         #EA462E;
$orange-dark:    #A04023;
$yellow:         #F0A334;
$yellow-dark:    #CC7927;
