

.panel{

  background-size: cover;
  
  .panel-header{
    border-left: 10px solid $orange;
    padding-left: 1em;
    margin-bottom: 1.5em;
  }

  a{
    text-decoration: underline;
  }

  &.grey{
    padding: 6em 0;
    background-color: $grey-mid;
    color: $white;

    a{
      color: $yellow;
    }
    .button{
      margin: 1em 0;
      color: $white;
      text-decoration: none;
      &:hover{
        color:$white;
      }
    }
  }

}
