
$topBarPadding:1.8em;

.topbar{
  @include animate;
  background-color: $white;
  position: fixed;
  z-index: 10;
  left:0;
  width:100%;
  padding: $topBarPadding;


  a{
    color: $white;
  }


  .sitelogo{
    img,
    svg{
      @include animate;
      @include scale(1);
      transform-origin: center;
      position: relative;
      top: 0;
      left: 0.2em;
      width: 100%;
      max-width: 300px;
    }
    g{
      fill:$white;
    }
  }

  .button{
    margin: 0;
  }

}


/* Modifiers */
/* =========================================== */
.topbar{
  &.active{
    @include animate;
    padding: 0.8em;
    background-color: $white;
    -webkit-box-shadow: 2px 5px 12px 3px rgba(0,0,0,0.18);
    box-shadow: 2px 5px 12px 3px rgba(0,0,0,0.18);

    .sitelogo{
      img,
      svg{
        @include animate;
        @include scale(0.85);
        top: 0;
        left: 0;
      }
      g{
        fill:$red;
      }
    }



  }
}
