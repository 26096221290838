



.hero-banner{
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;


  /* Overlay */
  &:before{
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: $black;
    opacity: 0.3;
  }

  .hero-text{
    position: relative;
    z-index: 5;
    text-align: center;
    h1{
      font-weight: bold;
    }
    p{
      font-size: 1.6em;
      max-width: 1200px;
      padding: 0 2em;
    }
  }

  .hero-summary{
    @include get-header-size(h2);
    font-weight: normal;
    font-family: $bodyFont;
  }
}


/* Modifiers */
/* =========================================== */
.hero-banner{

  @include breakpoint(small) {
    padding: 7em + ($topBarPadding/2) 0;;
  }
  @include breakpoint(xlarge) {
    padding: 7em + ($topBarPadding/2) 0;
  }

  &.small{
    @include breakpoint(small) {
      padding: 2em + ($topBarPadding/2) 0;;
    }
    @include breakpoint(xlarge) {
      padding: 4em + ($topBarPadding/2) 0;;
    }

  }

  &.large{
    @include breakpoint(small) {
      padding: 9em + ($topBarPadding) 0 4em 0;
    }
    @include breakpoint(medium) {
      padding: 11em + ($topBarPadding) 0 6em 0;
    }
    @include breakpoint(xlarge) {
      padding: 14em + ($topBarPadding) 0 10em 0;
    }

  }
}
