
.onwards-journey{
  position: relative;
  background: $orange;
  background-size: cover;
  //background-attachment: fixed;
  text-align: center;


  @include breakpoint(small) {
    padding: 8em 0;
  }
  @include breakpoint(large) {
    padding: 12em 0;
  }

  *{
    color: $white;
  }

  .content{
    position: relative;
    z-index: 2;
  }

  .block-title{
    @include get-header-size(h1);
    font-weight: bold;
    font-family: $titleFont;
  }
  .button-group{
    overflow: hidden;


    .button{
      @include border-radii(20em,0,20em,0);
      background: $white;
      color: $red;
      margin: 0 2px;

      @include breakpoint(small) {
        margin-bottom: 1em;
      }
      @include breakpoint(medium) {
        margin-bottom: 0;
      }

      &:hover{
        @include animate;
        @include scale(1);
        background: $yellow;
        color: $white;
        text-decoration: underline !important;
      }
    }

  }


  /* Overlay */
  &:before{
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    opacity: 0.25;
    background-color: $black;

  }
}
