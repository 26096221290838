
// Debug
.cell{
//  background: green;
}


// Scroller wrapper
#scrollContent{
  width: 100vw;
  height: 100vh;
}

main{
  margin: 4em 0;
}


// Padding for content blocks in the main tag
main{
  section{
    margin-top: 4em;
    margin-bottom: 4em;
  }
}



figure{
  img{
    width: 100%;
  }

  &.profile{
    text-align: center;
    img{
      object-fit: contain;
      width:70%;
    }
    caption{
      clear: both;
    }
  }
}
figcaption{
  padding: 1em;
  text-align: center;
}
