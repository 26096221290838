

// Radius
@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}


// Foundation header size
@mixin get-header-size($type){
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium){
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}


// Generic transform
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
   @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}

// Animation
@mixin animate($dur:0.4s,$delay:0s) {
  -webkit-transition: all $dur ease-in-out $delay;
  -moz-transition: all $dur ease-in-out $delay;
  -o-transition: all $dur ease-in-out $delay;
  -ms-transition: all $dur ease-in-out $delay;
  transition: all $dur ease-in-out $delay;
}



// Gradient
$grad1: $orange, $yellow;
// $list2: $blue 0%,$blue 33%,$green 33%,$green 66%, $orange 66%, $orange 100%;
// $list3: $blue 0%,$blue 10%,$green 25%,$green 39%,$orange 79%,$orange 100%;
// $list4: $blue, $green, $orange, $blue, $green, $orange, $blue, $green, $orange;

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

@mixin overlay($col:red,$dep:2,$opacity:0.5){
	content: '';
	position: absolute;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	background-color: $col;
	z-index: $dep;
	opacity: $opacity;
}
